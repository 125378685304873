import { render, staticRenderFns } from "./OnboardingCopystakeSettings.vue?vue&type=template&id=5fb3eb66&scoped=true&lang=pug"
import script from "./OnboardingCopystakeSettings.vue?vue&type=script&lang=ts"
export * from "./OnboardingCopystakeSettings.vue?vue&type=script&lang=ts"
import style0 from "./OnboardingCopystakeSettings.vue?vue&type=style&index=0&id=5fb3eb66&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb3eb66",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VForm,VIcon,VTextField,VTooltip})
